import React, { useState } from 'react'
import classNames from 'classnames'
import { IDropdownItemProps } from './type'

//TODO: Create a genric dropdown item which will have keyboard shortcuts as well.
const DropdownItem: React.FC<IDropdownItemProps> = ({
    className,
    disabled = false,
    selected,
    closeDropdown,
    children,
    open,
    onClick,
    height = 'auto',
    style,
    ...otherProps
}) => {
    const [hovered, setHovered] = useState(false)
    const toggleHover = () => setHovered(!hovered)
    const itemClassName = classNames(
        'ls-reset  w-100p px-3 dropdown-new__item',
        {
            'c-pointer': !disabled,
            'dropDownBg c-neutral-100 dropdown__selected': open,
            'c-neutral-900': !open && !selected && !hovered,
            'dropDownBg c-neutral-100': hovered || selected,
            'br-4': !hovered
        },
        className
    )

    function onItemClick(e: React.MouseEvent) {
        onClick?.(e)
        closeDropdown?.()
    }

    return (
        <li
            onClick={e => onItemClick(e)}
            {...otherProps}
            style={{
                paddingTop: '6px',
                paddingBottom: '6px',
                height: height,
                ...style
            }}
            className={itemClassName}
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
        >
            {children}
        </li>
    )
}

export default DropdownItem
