import React, { Children } from 'react'
import classNames from 'classnames'
import { Spinner } from '@cleartrip/ct-design-components'

import { normalizeSize } from '@/utils/general/uiHelper'

import { IDropdownMenuProps } from './type'

const DropdownMenu: React.FC<IDropdownMenuProps> = ({
    loading,
    onCloseDropdown,
    children,
    open,
    className,
    width,
    position = 'left',
    distance = 8,
    offset,
    style,
    title,
    ...otherProps
}) => {
    const MenuClassName = classNames(
        'bg-neutral-100 br-12 p-absolute z-50 w-100p',
        {
            'r-0 nmr-3': position === 'right',
            'l-0 t-48': position === 'left'
        },
        className
    )

    const dropdownWidth = width ? normalizeSize(width) : undefined
    const dropdownOffset = offset ? normalizeSize(offset) : undefined
    const commonStyle = {
        marginTop: normalizeSize(distance),
        boxShadow: '0px 4px 16px 0px #1A1A1A14',
        width: dropdownWidth,
        ...style
    }
    const leftPos = {
        left: dropdownOffset,
        ...commonStyle
    }
    const rightPos = {
        right: dropdownOffset,
        ...commonStyle
    }
    let MenuBlock = <></>

    if (open) {
        MenuBlock = (
            <div
                {...otherProps}
                style={
                    position === 'left' ? { ...leftPos, width: dropdownWidth } : { ...rightPos, width: dropdownWidth }
                }
                className={MenuClassName}
            >
                {title && (
                    <div className=" fs-2" style={{ color: '#b3b3b3', padding: '8px 16px' }}>
                        {title}
                    </div>
                )}
                {loading ? (
                    <div className="p-2">
                        <Spinner />
                    </div>
                ) : (
                    Children.map(children, child => {
                        if (child !== null) return React.cloneElement(<>{child}</>, { closeDropdown: onCloseDropdown })
                    })
                )}
            </div>
        )
    }

    return <>{MenuBlock}</>
}

export default DropdownMenu
