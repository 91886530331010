import React, { useState, useEffect, Children } from 'react'
import { OutsideClickHandler } from '@cleartrip/ct-design-components'
import classNames from 'classnames'
import { IDropdownProps, IDropdownSubComponents } from './type'

// TODO(@Khushahal @Prakhar): do sanity for props
const Dropdown: React.FC<IDropdownProps> & IDropdownSubComponents = ({
    className,
    children,
    isTyping = false,
    toggle,
    onOutsideClick,
    ...otherProps
}) => {
    const [open, setOpen] = useState(false)
    useEffect(() => {
        if (toggle) setOpen(false)
        isTyping && setOpen(isTyping)
    }, [toggle, isTyping])

    const getConnectedChildren = () => {
        const connectedChildren = []
        const dropdownTrigger = Children.toArray(children)[0]
        const dropdownMenu = Children.toArray(children)[1]

        // @ts-ignore
        const connectedDropdownTrigger = dropdownTrigger
            ? // @ts-ignore
              React.cloneElement(dropdownTrigger, {
                  toggleDropdown: (open: boolean) => setOpen(open),
                  open: open
              })
            : null

        // @ts-ignore
        const connectedDropdownMenu = dropdownMenu
            ? // @ts-ignore
              React.cloneElement(dropdownMenu, {
                  open: open,
                  onCloseDropdown: () => setOpen(false)
              })
            : null
        connectedChildren.push(connectedDropdownTrigger, connectedDropdownMenu)
        return connectedChildren
    }

    const handleOutsideClick = () => {
        onOutsideClick?.()
        setOpen(false)
    }

    return (
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
            <div {...otherProps} className={classNames('p-relative', className)}>
                {children}
            </div>
        </OutsideClickHandler>
    )
}

export default Dropdown
