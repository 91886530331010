export interface FareTypeInterface {
    fare: string
    code: string
    active: boolean
    toolTipMsg?: string
    toolTipErrorMsg?: string
}

export const FARE_TYPE: FareTypeInterface[] = [
    // { fare: 'Regular fare', code: 'rf', active: true },
    {
        fare: 'Senior citizen fare',
        code: 'senior_citizen',
        active: true,
        toolTipMsg: 'Only senior citizens above the age of 60 years can avail this special fare.',
        toolTipErrorMsg: 'You cannot select Senior citizen fare with infants/child added to the journey'
    },
    {
        fare: 'Student fare',
        code: 'student',
        active: true,
        toolTipMsg:
            'Only students above the age of 12 with valid ID can avail this special fare and any additional baggage allowance',
        toolTipErrorMsg: 'You cannot select Student fare with infants/child added to the journey'
    },
    {
        fare: 'Armed forces fare',
        code: 'army',
        active: true,
        toolTipMsg: 'Only serving/retired Indian armed forces personnel & their dependents can avail this fare.'
    }
]
export function returnFareName(code: string) {
    let fareName = ''
    FARE_TYPE.map(fares => {
        if (fares.code === code) fareName = fares.fare
    })
    return fareName
}

export function returnFareNameItin(fareType: string) {
    switch (fareType) {
        case 'STUDENT FARE':
            return 'Student fare'
        case 'ARMED FORCES FARE':
            return 'Armed forces fare'
        case 'SENIOR CITIZEN FARE':
            return 'Senior citizen fare'
        default:
            return 'Regular fare'
    }
}
