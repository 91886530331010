import { Container, Typography } from '@cleartrip/ct-design-components'
import React, { useState, useEffect } from 'react'
import { SVGProps } from 'react'

const DownArrow = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={15} height={6} fill="none" transform="rotate(180)" {...props}>
            <path
                fill="#1A1A1A"
                d="M11.94 4.458C12.772 5.45 13.865 6 15 6H0c1.135 0 2.228-.55 3.061-1.542L6.303.603C6.979-.2 8.02-.2 8.697.603l3.242 3.855Z"
            />
        </svg>
    )
}

const CfwCheckboxTooltip = () => {
    const [visible, setVisible] = useState(true)
    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false)
        }, 3000)

        return () => clearTimeout(timer)
    }, [])

    if (!visible) return null

    return (
        <Container className="p-absolute l-0 flex flex-column" style={{ zIndex: '100', top: '-36px' }}>
            <Typography
                variant="B3"
                color="neutral"
                style={{ background: '#1A1A1A', borderRadius: '8px' }}
                className="p-3"
            >
                Select to avail benefits
            </Typography>
            <DownArrow style={{ marginLeft: '16px' }} />
        </Container>
    )
}

export default CfwCheckboxTooltip
