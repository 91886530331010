import { ravenSDKTrigger } from '@/analytics/raven/ravenSDKHelper'
import { getCookie } from '@/utils/general/cookie'
import { Button, Container } from '@cleartrip/ct-design-components'
import React, { FC } from 'react'

type CfwErrorModalButtonType = {
    cfwErrorModal: String
    cfwErrorModalButtonText: String
    isCfwSelected: boolean
    setActiveFareHandler: (value: string) => void
    setIsCfwSelected: (value: boolean) => void
    isIntlArray: boolean[]
    setCfwErrorModal: (value: string) => void
    setCfwErrorModalButtonText: (value: string) => void
    setActiveFare: (value: string) => void
}
const CfwErrorModalButton: FC<CfwErrorModalButtonType> = ({
    cfwErrorModal,
    cfwErrorModalButtonText,
    isCfwSelected,
    setActiveFareHandler,
    setIsCfwSelected,
    isIntlArray,
    setCfwErrorModal,
    setCfwErrorModalButtonText,
    setActiveFare
}) => {
    const switchCfw = () => {
        if (isCfwSelected) {
            setIsCfwSelected(false)
            if (cfwErrorModal.includes('Senior citizen fare')) {
                setActiveFare('senior_citizen')
            } else if (cfwErrorModal.includes('Student fare')) {
                setActiveFare('student')
            } else if (cfwErrorModal.includes('Armed forces fare')) {
                setActiveFare('army')
            }
            const ravenPayload = {
                action_name: 'switch_to_ptc_fare_click',
                action_type: 'click',
                page_name: 'flights_home',
                a_utm_source:
                    location?.search?.split?.('utm_source=').length > 1
                        ? location?.search?.split?.('utm_source=')?.pop()?.split('&')?.[0]
                        : getCookie('utm_source') || 'organic'
            }
            ravenSDKTrigger('a_ui_action', ravenPayload)
        } else if (!(isIntlArray[0] || isIntlArray[1])) {
            setActiveFareHandler('')
            setIsCfwSelected(true)
            const ravenPayload = {
                action_name: 'switch_to_cfw_fare_click',
                action_type: 'click',
                page_name: 'flights_home',
                a_utm_source:
                    location?.search?.split?.('utm_source=').length > 1
                        ? location?.search?.split?.('utm_source=')?.pop()?.split('&')?.[0]
                        : getCookie('utm_source') || 'organic'
            }
            ravenSDKTrigger('a_ui_action', ravenPayload)
        }
        setCfwErrorModal('')
        setCfwErrorModalButtonText('Okay, got it')
    }
    return (
        <Container className="mt-8">
            <Button
                className="p-3"
                style={{ height: '48px', width: '344px', backgroundColor: '#1A1A1A' }}
                onClick={switchCfw}
            >
                {cfwErrorModalButtonText}
            </Button>
        </Container>
    )
}

export default CfwErrorModalButton
