import React from 'react'
import classNames from 'classnames'
import { CalendarOld, Calendar, SelectedCalendar } from '@/assets/icons'
import { Container } from '@cleartrip/ct-design-components'
import { IDropdownSelectProps } from './type'

const DropdownSelect: React.FC<IDropdownSelectProps> = ({
    placeHolder,
    size,
    className = '',
    label,
    open,
    hasError,
    toggleDropdown,
    border = true,
    getLabel = () => {
        return ''
    },
    onClick,
    full,
    name,
    calendarOpen,
    active = false,
    ...otherProps
}) => {
    const selectClassName = classNames(
        'focus:bc-secondary-500 t-all c-pointer flex flex-middle flex-between bg-neutral-100 c-neutral-900',
        {
            'p-4 py-3 px-4 h-10 fs-16': size === 'lg',
            'p-4 py-2 px-2 h-9 fs-12': size === 'md',
            'p-4 py-1 px-3 h-8 fs-8': size === 'sm',
            'py-0 fs-8 h-40': size === 'xs',
            'ba-solid bc-default': border && !open && !hasError,
            'bc-secondary-500 bc-default ba-solid': open && border && !hasError,
            'bc-error-500 bc-default ba-solid': hasError,
            'w-100p': full
        },
        className
    )

    function handleClick(e: React.MouseEvent) {
        e.preventDefault()
        toggleDropdown?.(!open)
        onClick(e)
    }

    const datePickerWidth = {
        height: size === 'xs' ? '40px' : '52px',
        borderRadius: name != 'from' && name != 'to' ? '6px' : '',
        borderColor: hasError ? '#FF3C2B' : open ? '#3366CC' : '#E6E6E6'
    }

    const selectLabel = label || getLabel(!!open)

    const renderPipeSymbol = () => {
        if (name === 'from') {
            return (
                <>
                    <div
                        className="flex flex-middle divider-wrapper"
                        style={{ borderColor: hasError ? '#FF3C2B' : calendarOpen ? '#3366CC' : '#E6E6E6' }}
                    >
                        <div
                            className="divider"
                            style={{
                                backgroundColor: calendarOpen && !hasError ? '#3366CC' : '#E6E6E6',
                                height: calendarOpen && !hasError ? 52 : 36
                            }}
                        ></div>
                    </div>
                </>
            )
        }
    }

    return (
        <>
            <div
                {...otherProps}
                className={`${selectClassName} ${name === 'from' && 'fromDiv'} ${name === 'to' && 'toDiv'}`}
                onClick={e => handleClick(e)}
                style={{ ...datePickerWidth }}
            >
                {selectLabel ? (
                    <>
                        {' '}
                        {name === 'from' &&
                            (open ? (
                                <Container className="mr-2">
                                    <SelectedCalendar />
                                </Container>
                            ) : (
                                <Container className="mr-2 mt-1">{<CalendarOld />}</Container>
                            ))}
                        <div className="c-inherit flex flex-1 flex-nowrap fs-16 fw-500 card-price">{selectLabel}</div>
                    </>
                ) : (
                    <span className=" c-neutral-400 flex flex-nowrap fs-16 fw-500">{placeHolder}</span>
                )}
            </div>
            {renderPipeSymbol()}
        </>
    )
}

export default DropdownSelect
