import React from 'react'
import { TextField } from '@cleartrip/ct-design-components'
import { TextFieldProps } from '@cleartrip/ct-design-components'

import { Location, HighlightedLocation, Cross } from '@/assets/icons'

const DropdownField: React.FC<TextFieldProps & { isFocus?: boolean; showClear?: boolean; onClear?: () => void }> = ({
    name,
    isFocus = false,
    prefix,
    className,
    showClear,
    onClear,
    ...otherProps
}) => {
    return (
        <div className={className}>
            <TextField
                className="fs-16 lh-24 fw-500 "
                id={name}
                autoFocus={isFocus}
                prefix={prefix || (!isFocus ? <Location stroke="gray" /> : <HighlightedLocation />)}
                suffix={
                    showClear && otherProps.value && otherProps.value.toString().length > 0 ? (
                        <Cross onClick={onClear} className="c-pointer" />
                    ) : null
                }
                styleConfig={{
                    root: {
                        className: 'field-root'
                    },
                    placeholderTypography: {
                        className: 'fw-500 fs-16 lh-24'
                    },

                    fieldContainer: {
                        css: {
                            width: 'auto',
                            flex: 1
                        }
                    }
                }}
                {...otherProps}
            />
        </div>
    )
}

export default DropdownField
