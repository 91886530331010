import React from 'react'
import { returnFareName } from '@/constants/components/fareType'
import { Chip, TooltipV2 } from '@cleartrip/ct-design-components'
import { RAVEN_ATTRIBUTE_NAME, RAVEN_EVENT_NAME, RAVEN_PAGE_NAME } from '@/analytics/raven/ravenConstants'
import { Raven } from '@/analytics/raven'
import Clevertap from '@/analytics/clevertap/index'
import { ACTION_NAMES } from '@/constants/analytics'
interface FareType {
    code: string
    fare: string
    active: boolean
    toolTipMsg?: string
    toolTipErrorMsg?: string
}

interface SplFareTypeProps {
    activeFare: string
    fareTypeList: FareType[]
    tripType: string
    handleClick: (type: string) => void
}

const SplFareType: React.FC<SplFareTypeProps> = ({ activeFare, fareTypeList, tripType, handleClick }) => {
    function ClickHandler(type: string, active: boolean) {
        if (!active) {
            return
        }
        Raven.push({
            eventName: RAVEN_EVENT_NAME.HOME_PTC_FARE_SELECTED,
            eventData: {
                action_name: RAVEN_EVENT_NAME.HOME_PTC_FARE_SELECTED,
                page_name: RAVEN_PAGE_NAME.FLIGHTS_HOME,
                a_fare_type: type
            }
        })
        handleClick(type)
    }

    const disableClickHandler = (ele: FareType) => {
        Clevertap.event('Air_UI_Action', {
            action_name: ACTION_NAMES.ptcFareSelectionError,
            ptc_fare: returnFareName(ele.code),
            is_ptc_selectable: returnFareName(activeFare) === 'Regular fare' ? 'no' : 'yes',
            ptc_fare_selection_error: `You cannot select ${ele.fare} with infants added to the journey`,
            journey_type_uiaction: tripType === 'O' ? 'one-way' : 'round-trip'
        })
    }

    const onHoverHandler = (code: string) => {
        if (code !== '') {
            Raven.push({
                eventName: RAVEN_EVENT_NAME.HOME_PTC_ICON_HOVER,
                eventData: {
                    action_name: RAVEN_ATTRIBUTE_NAME.HP_PTC_ICON_HOVER,
                    page_name: RAVEN_PAGE_NAME.FLIGHTS_HOME
                }
            })
        }
        Clevertap.event('Air_UI_Action', {
            action_name: ACTION_NAMES.ptcIconHover,
            action_type: 'hover',
            ptc_fare: returnFareName(code),
            journey_type_uiaction: tripType === 'O' ? 'one-way' : 'round-trip'
        })
    }

const CloseIcon = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
        <path stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round" d="M12 4 8 8m0 0-4 4m4-4L4 4m4 4 4 4" />
    </svg>
)


    const fareTypes = fareTypeList.map((ele, idx) => {
        return (
            <div onClick={() => ClickHandler(ele.code, ele.active)} key={idx}>
                <TooltipV2
                    position="bottom"
                    styleConfig={{
                        tooltipContainer: {
                            css: {
                                color: 'white',
                                width: '300px'
                            },
                            className: 'p-3'
                        }
                    }}
                    labelContainer={
                        <div onMouseEnter={() => onHoverHandler(ele.code)}>
                            <Chip
                                styleConfig={{
                                    labelTypography: {
                                        className: 'c-pointer fw-400'
                                    },
                                    root: {
                                        className: `c-pointer`
                                    }
                                }}
                                key={ele.fare}
                                label={ele.fare}
                                isSelected={ele.code === activeFare}
                                isDisabled={!ele.active}
                                showIcon={ele.code === activeFare}
                                iconPosition='right'
                                Icon={<><CloseIcon style={{marginTop:'1px'}}/></>}
                            />
                        </div>
                    }
                >
                    {ele.active ? ele.toolTipMsg : ele.toolTipErrorMsg}
                </TooltipV2>
            </div>
        )
    })

    return <div className="flex flex-middle mt-6 flex-cg-2">{fareTypes}</div>
}

export default SplFareType
