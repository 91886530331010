import { ancillaryData } from '@/mocks/ancillary'
import { isEmpty } from '@/utils/general/browserHelper'
export type JsonObjectType = typeof ancillaryData
export const BAGGAGE = 'BAGGAGE'
export const MEAL = 'MEAL'
export const SEAT = 'SEAT'
export const INSURANCE = 'INSURANCE'
export const makeCommonMealBagObject = (object: Record<string, any>, type: 'BAGGAGE' | 'MEAL') => {
    let finalObject = {
        baggageType: '',
        title: '',
        price: 0,
        isInclusiveFreeBaggage: false,
        strikeOffDetails: null,
        code: '',
        paxTypeList: ['ADULT'],
        imageUrl: '',
        mealType: '',
        refundable: false,
        type: ''
    }
    if (type === MEAL) {
        finalObject.code = object.code
        finalObject.title = object.description
        finalObject.price = object.price
        finalObject.strikeOffDetails = object.strikeOffDetails
        finalObject.refundable = object.refundable
        finalObject.paxTypeList = object.paxTypeList
        finalObject.type = MEAL
        finalObject.imageUrl = object.mealImageUrl
        finalObject.mealType = object.mealType
    } else if (type === BAGGAGE) {
        finalObject.code = object.baggageCode
        finalObject.title = object.displayDetail
        finalObject.price = object.price
        finalObject.strikeOffDetails = object.strikeOffDetails
        finalObject.refundable = object.isRefundable
        finalObject.paxTypeList = object.paxTypes
        finalObject.type = BAGGAGE
        finalObject.baggageType = object.baggageType
        finalObject.isInclusiveFreeBaggage = object.isInclusiveFreeBaggage
    }
    return finalObject
}
export const totalTravellers = (paxInfo: Record<string, any>) => {
    return paxInfo.adults + paxInfo.children
}
export function smbDataExtract(ancillaryData: JsonObjectType, SEARCH_DETAILS: Record<string, any>) {
    let { paxInfo = {} } = SEARCH_DETAILS
    let total = totalTravellers(paxInfo)
    let smbArray: string[] = []
    let onwardBaggage: Record<string, any>[] = []
    let returnBaggage: Record<string, any>[] = []
    let onwardSegmentAncillaries: Record<string, any>[] = []
    let returnSegmentAncillaries: Record<string, any>[] = []
    let isMealSelected = false,
        isBagSelected = false,
        isSeatSelected = false
    let baggageLeftBar: Record<string, any>[][] = [],
        mealLeftBar: Record<string, any>[][] = [],
        seatLeftBar: Record<string, any>[][] = []
    let totalSegment = 0
    const { tripAncillariesResponse = [] } = ancillaryData
    const { ancillaryDetails: { journeyAncillaries = [] } = {} } = tripAncillariesResponse[0] || {}
    journeyAncillaries.forEach((journey, index) => {
        const { baggageAncillary, segmentAncillaries = [] } = journey || {}
        const { baggageDetails = [] } = !isEmpty(baggageAncillary) ? baggageAncillary : {}
        const { baggageInfos, originDestinationInfo = {} } = baggageDetails[0] || {}
        baggageLeftBar[index] = [
            { ...originDestinationInfo, count: 0, total: total, isSelected: false, isDisable: true, type: 'BAGGAGE' }
        ]
        if (!isEmpty(baggageAncillary) && baggageInfos.length) {
            baggageLeftBar[index][0].isDisable = false
            if (!isBagSelected) {
                baggageLeftBar[index][0].isSelected = true
                isBagSelected = true
            }
            if (!smbArray.includes(BAGGAGE)) smbArray.push(BAGGAGE)
        }
        seatLeftBar[index] = []
        mealLeftBar[index] = []
        segmentAncillaries.forEach((segment, segmentIndex) => {
            const {
                seatAncillaries: { aircraftLayout = {} } = {},
                mealAncillaries: { mealInfos = [] } = {},
                originDestinationInfo = {},
                flight = {}
            } = segment || {}
            seatLeftBar[index].push({
                ...originDestinationInfo,
                ...flight,
                count: 0,
                total: total,
                isSelected: false,
                isDisable: true,
                type: SEAT
            })
            mealLeftBar[index].push({
                ...originDestinationInfo,
                ...flight,
                count: 0,
                total: total,
                isSelected: false,
                isDisable: true,
                type: MEAL
            })
            if (!isEmpty(aircraftLayout)) {
                seatLeftBar[index][segmentIndex].isDisable = false
                if (!isSeatSelected) {
                    seatLeftBar[index][segmentIndex].isSelected = true
                    isSeatSelected = true
                }
                if (!smbArray.includes(SEAT)) smbArray.push(SEAT)
            }
            if (mealInfos.length) {
                mealLeftBar[index][segmentIndex].isDisable = false
                if (!isMealSelected) {
                    mealLeftBar[index][segmentIndex].isSelected = true
                    isMealSelected = true
                }
                if (!smbArray.includes(MEAL)) smbArray.push(MEAL)
            }
            totalSegment += 1
        })

        if (index === 0) {
            onwardBaggage = baggageDetails
            onwardSegmentAncillaries = segmentAncillaries
        } else {
            returnBaggage = baggageDetails
            returnSegmentAncillaries = segmentAncillaries
        }
    })
    calculateIfNextAvailable(seatLeftBar)
    calculateIfNextAvailable(baggageLeftBar)
    calculateIfNextAvailable(mealLeftBar)
    return {
        totalSegment,
        smbArray,
        onwardBaggage,
        returnBaggage,
        onwardSegmentAncillaries,
        returnSegmentAncillaries,
        baggageLeftBar,
        mealLeftBar,
        seatLeftBar
    }
}
function calculateIfNextAvailable(leftBar: Record<string, any>[][]) {
    let isSegmentDataAvailable = false
    for (let indexOuter = leftBar.length - 1; indexOuter >= 0; indexOuter--) {
        const journey = leftBar[indexOuter]
        for (let index = journey.length - 1; index >= 0; index--) {
            let element = journey[index]
            if (index === journey.length - 1 && indexOuter === leftBar.length - 1) {
                element.isNext = false
            }
            if (isSegmentDataAvailable) {
                element.isNext = true
            } else {
                element.isNext = false
            }
            if (!element.isDisable) {
                isSegmentDataAvailable = true
            }
        }
    }
}
export const convertSelectedDetail = (
    object: Record<string, any>,
    type: typeof MEAL | typeof BAGGAGE | typeof SEAT
) => {
    let finalObject = {
        title: '',
        price: 0,
        strikeOffPrice: 0,
        isInclusiveFreeBaggage: false,
        strikeOffDetails: null,
        code: '',
        type: type
    }
    if (type === BAGGAGE) {
        finalObject.title = object.baggageInfo
        finalObject.code = object.baggageCode
        finalObject.price = object.price
        finalObject.isInclusiveFreeBaggage = object.isInclusiveFreeBaggage
    } else if (type === MEAL) {
        finalObject.title = object.mealInfo
        finalObject.code = object.mealCode
        finalObject.price = object.price
        finalObject.strikeOffPrice = object.strikeOffPrice
    } else if (type == SEAT) {
        return {
            rowId: object.rowId,
            columnId: object.columnId,
            price: object.price,
            strikeOffPrice: object.strikeOffPrice,
            type
        }
    }
    return finalObject
}
export function findSelectedLeftBar(leftBar: Record<string, any>[][]) {
    for (let index = 0; index < leftBar.length; index++) {
        const journey = leftBar[index]
        for (let index = 0; index < journey.length; index++) {
            const element = journey[index]
            if (element.isSelected) return element
        }
    }
    return {}
}
export function findSelectedSegment(ancilary: any, selectedLeftBar: Record<string, any>) {
    for (let index = 0; index < ancilary.length; index++) {
        const journey = ancilary[index]
        for (let index = 0; index < journey.length; index++) {
            const element = journey[index]
            const { originDestinationInfo = {} } = element || {}
            if (
                originDestinationInfo.from === selectedLeftBar.from &&
                originDestinationInfo.to === selectedLeftBar.to
            ) {
                return element
            }
        }
    }
    return {}
}
export const updateLeftBar = (
    leftBar: Record<string, any>[][],
    obj: Record<string, any>,
    type: 'SELECT' | 'NEXT',
    updateLeftBar: (data: Record<string, any>[][]) => void
) => {
    let tempLeftBar = JSON.parse(JSON.stringify(leftBar))
    let elementFound = false
    for (let indexOuter = 0; indexOuter < tempLeftBar.length; indexOuter++) {
        const journey = tempLeftBar[indexOuter]
        for (let index = 0; index < journey.length; index++) {
            let element = journey[index]
            switch (type) {
                case 'SELECT': {
                    if (obj.from === element.from && obj.to === element.to) {
                        element.isSelected = true
                    } else element.isSelected = false
                    break
                }
                case 'NEXT': {
                    if (elementFound) {
                        if (!element.isDisable) {
                            element.isSelected = true
                            elementFound = false
                        }
                    }
                    if (obj.from === element.from && obj.to === element.to) {
                        elementFound = true
                        element.isSelected = false
                    }
                    break
                }
            }
        }
    }
    updateLeftBar(tempLeftBar)
}

export const calculateTotalPrice = (leftBar: Record<string, any>[][]) => {
    let totalPrice = 0
    let strikePrice = 0
    for (let indexOuter = 0; indexOuter < leftBar.length; indexOuter++) {
        const journey = leftBar[indexOuter]
        for (let index = 0; index < journey.length; index++) {
            let element = journey[index]
            let details = []
            if (element.type === MEAL) {
                details = element.mealDetails
            } else if (element.type === BAGGAGE) {
                details = element.baggageDetails
            } else if (element.type === SEAT) {
                details = element.seatDetails
            }
            if (details) {
                details.forEach((item: Record<string, any>) => {
                    totalPrice += item.price
                    if (!isEmpty(item.strikeOffDetails)) {
                        strikePrice += item.strikeOffDetails.strikeOffAmount
                        totalPrice += item.strikeOffDetails.displayAmount
                    }
                })
            }
        }
    }
    return { totalPrice, strikePrice }
}

export function findDoneValue(leftBar: Record<string, any>[][]) {
    for (let index = 0; index < leftBar.length; index++) {
        const journey = leftBar[index]
        for (let index = 0; index < journey.length; index++) {
            const element = journey[index]
            if (element.type === MEAL) {
                if (element.mealDetails?.length) return true
            }
            if (element.type === BAGGAGE) {
                if (element.baggageDetails?.length) return true
            }
            if (element.type === SEAT) {
                if (element.seatDetails?.length) return true
            }
        }
    }
    return false
}

export function checkIfAnyAncilliarySelected(addonsData, type, insuranceData) {
    let setFlag = false
    addonsData?.userSelectedAncillaries?.userSelectedJourneyAncillaries?.forEach((journeyItem: any) => {
        switch (type) {
            case SEAT:
                journeyItem?.userSelectedSegmentAncillaries?.forEach((segmentItem: any) => {
                    if (segmentItem?.seatDetails?.length > 0) setFlag = true
                })
                break
            case MEAL:
                journeyItem?.userSelectedSegmentAncillaries?.forEach((segmentItem: any) => {
                    if (segmentItem?.mealDetails?.length > 0) setFlag = true
                })
                break
            case BAGGAGE:
                if (journeyItem?.userSelectedBaggageAncillaries?.[0]?.baggageDetails?.length > 0) setFlag = true
                break
            default:
                break
        }
    })
    if (type === INSURANCE) {
        insuranceData?.[0]?.insuranceDetails?.forEach((insuranceItem: any) => {
            if (insuranceItem.selected === true) setFlag = true
        })
    }
    return setFlag
}

export const getInsuranceData = insuranceObj => {
    const { insurances = [] } = insuranceObj || {}
    return insurances
}
