import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import DayPicker, { DateUtils, NavbarElementProps, DayModifiers } from 'react-day-picker'

import { RightArrow, LeftArrow } from '@/assets/icons/Arrows'

import 'react-day-picker/lib/style.css'
import { dayDiff, getFormattedDate, getMonthRange } from '@/utils/general/date'
import { isEmpty } from '@/utils/general/browserHelper'
import { RAVEN_EVENT_NAME, RAVEN_PAGE_NAME } from '@/analytics/raven/ravenConstants'
import { Raven } from '@/analytics/raven'
import Clevertap from '@/analytics/clevertap/index'

interface FareCalendar {
    onward: { [key: string]: { pr: string } }
    cheapFare: { [key: string]: string }
}
interface DateRange {
    from: Date | null
    to: Date | null
    enteredTo: Date | null
}
interface IOwnProps {
    selectedDates: DateRange
    className?: string
    showDateFare?: boolean
    title?: string
    isRange?: boolean
    onClickCalendar?: (dates: DateRange) => void
    noOfMonths?: number
    domain?: string
    weekShort?: string[]
    dayElement?: (date: Date, modifiers: DayModifiers) => React.ReactNode
    roundTrip: boolean
    fareCalendar: any
    directionClick: (monthRange: string) => void
    index: number
    activeOnward: boolean
    activeReturn: boolean
}

const Calendar: React.FC<IOwnProps> = ({
    selectedDates,
    className,
    title,
    roundTrip,
    onClickCalendar,
    activeOnward,
    activeReturn,
    index,
    showDateFare = true,
    ...otherProps
}) => {
    const NO_OF_MONTHS = 2

    const today = new Date()
    const toMonth = new Date(new Date().setFullYear(new Date().getFullYear() + 1))
    const [selectedDate, setSelectedDate] = useState<Date | null>(selectedDates.from)
    const [range, setRange] = useState<DateRange>(selectedDates)
    const dayPickerClassName = classNames(className, {
        Selectable: roundTrip
    })

    const { from, to, enteredTo } = range
    const modifiers = { start: from, end: enteredTo, leftEdge: { daysOfWeek: [1] }, rightEdge: { daysOfWeek: [0] } }
    const disabledDays = {
        before: activeReturn && range.from ? range.from : today,
        after: toMonth
    }
    const { fareCalendar = {}, directionClick } = otherProps

    useEffect(() => {
        setRange(selectedDates)
    }, [selectedDates])

    const isSelectingFirstDay = (from: Date | null, to: Date | null, day: Date): boolean => {
        if (roundTrip && activeReturn) {
            const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from)
            const isRangeSelected = from && to
            return !!(!isBeforeFirstDay || isRangeSelected)
        }
        return false
    }

    const handleDayMouseEnter = (day: Date): void => {
        const { from, to } = range
        if (isSelectingFirstDay(from, to, day)) {
            setRange({ ...range, enteredTo: day })
        }
    }

    const handleDayClick = (day: Date): void => {
        if (roundTrip && activeOnward) {
            if (from && to) {
                let diff = dayDiff(day, to)
                if (diff >= 1) {
                    setSelectedDate(day)
                    onClickCalendar?.({
                        from: day,
                        to: day,
                        enteredTo: day
                    })
                } else {
                    setSelectedDate(day)
                    onClickCalendar?.({
                        ...range,
                        from: day
                    })
                }
            } else if (from && isEmpty(to)) {
                setSelectedDate(day)
                onClickCalendar?.({
                    ...range,
                    from: day
                })
            }
            Clevertap.event('Air_UI_Action', {
                action_type: 'BUTTON',
                action_name: 'SRP_OW_Date_Selected'
            })
            Raven.push({
                eventName: RAVEN_EVENT_NAME.HOME_OW_DATE_SELECTED,
                eventData: {
                    action_name: RAVEN_EVENT_NAME.HOME_OW_DATE_SELECTED,
                    page_name: RAVEN_PAGE_NAME.FLIGHTS_HOME
                }
            })
        } else if (activeReturn) {
            Clevertap.event('Air_UI_Action', {
                action_type: 'BUTTON',
                action_name: 'SRP_RT_Date_Selected'
            })
            Raven.push({
                eventName: RAVEN_EVENT_NAME.HOME_RT_DATE_SELECTED,
                eventData: {
                    action_name: RAVEN_EVENT_NAME.HOME_RT_DATE_SELECTED,
                    page_name: RAVEN_PAGE_NAME.FLIGHTS_HOME
                }
            })
            setSelectedDate(day)
            onClickCalendar?.({
                ...range,
                to: day,
                enteredTo: day
            })
        } else {
            Clevertap.event('Air_UI_Action', {
                action_type: 'BUTTON',
                action_name: 'SRP_OW_Date_Selected'
            })
            Raven.push({
                eventName: RAVEN_EVENT_NAME.HOME_OW_DATE_SELECTED,
                eventData: {
                    action_name: RAVEN_EVENT_NAME.HOME_OW_DATE_SELECTED,
                    page_name: RAVEN_PAGE_NAME.FLIGHTS_HOME
                }
            })
            setSelectedDate(day)
            onClickCalendar?.({
                from: day,
                to: null,
                enteredTo: null
            })
        }
    }

    const nextClick = (onNextClick: () => void, previousMonth: Date): void => {
        let monthRange = getMonthRange(previousMonth.toString(), 'right')
        directionClick(monthRange)
        onNextClick()
    }

    const previousClick = (onPreviousClick: () => void, previousMonth: Date): void => {
        let monthRange = getMonthRange(previousMonth.toString(), 'left')
        directionClick(monthRange)
        onPreviousClick()
    }

    const Navbar: React.FC<NavbarElementProps> = ({ onPreviousClick, onNextClick, previousMonth, nextMonth }) => {
        return (
            <div className="flex flex-middle flex-between p-absolute w-100p px-5">
                <div className={classNames('flex-1 ta-left')}>
                    {(previousMonth.getFullYear() === today.getFullYear() &&
                        previousMonth.getMonth() < today.getMonth()) ||
                    (previousMonth.getFullYear() < today.getFullYear() &&
                        previousMonth.getMonth() > today.getMonth()) ? (
                        <LeftArrow height={20} width={20} className="c-neutral-200" />
                    ) : (
                        <LeftArrow
                            data-testid="leftArrow"
                            height={20}
                            width={20}
                            className="c-pointer"
                            onClick={() => previousClick(onPreviousClick, previousMonth)}
                        />
                    )}
                </div>

                <div className={classNames('flex-1 ta-right')}>
                    {nextMonth.getTime() > toMonth.getTime() ? (
                        <RightArrow
                            height={20}
                            width={20}
                            style={{ alignSelf: 'flex-end' }}
                            className="c-neutral-200"
                        />
                    ) : (
                        <RightArrow
                            data-testid="rightArrow"
                            height={20}
                            width={20}
                            style={{ alignSelf: 'flex-end' }}
                            className="c-pointer"
                            onClick={() => nextClick(onNextClick, previousMonth)}
                        />
                    )}
                </div>
            </div>
        )
    }

    const renderDay = (day: Date): React.ReactNode => {
        const date = day.getDate()
        const lastday = new Date(day.getFullYear(), day.getMonth() + 1, 0).getDate()
        const formattedDate = getFormattedDate(day, 'DD-MM-YYYY')
        const fullMonth = getFormattedDate(day, 'DD-MMMM-YYYY')
        const month = fullMonth.split('-')[1]
        const fareData = fareCalendar.onward ? fareCalendar.onward[formattedDate] : null
        const cheapFare = fareCalendar.cheapFare ? fareCalendar.cheapFare : null

        return (
            <div
                className={classNames('Day-grid flex flex-middle flex-column', roundTrip ? 'flex-center' : 'flex-top')}
            >
                <div
                    className={classNames(
                        'p-1 day-gridContent fs-14 fw-500 flex flex-middle flex-column flex-center',
                        roundTrip ? 'Round-trip' : 'flex-top',
                        date === 1 ? 'monthStart' : '',
                        date === lastday ? 'monthEnd' : ''
                    )}
                    style={{ height: '45px' }}
                >
                    {date}

                    {showDateFare && (
                        <>
                            {fareData && fareData.pr !== undefined && fareData.pr !== '-' && (
                                <div
                                    className={classNames(
                                        'fs-10 lh-12 Datepicker--price',
                                        cheapFare[month] === fareData.pr ? 'c-success-500' : 'c-neutral-400'
                                    )}
                                >
                                    {fareData.pr}
                                </div>
                            )}
                            {fareData && fareData.pr === '-' && (
                                <div className="fs-10 lh-12 Datepicker--price c-neutral-400">-</div>
                            )}
                        </>
                    )}
                </div>
            </div>
        )
    }

    const MONTHS = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
    ]
    const weekSort = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
    const FIRST_DAY_OF_WEEK = {
        IN: 1,
        AE: 0,
        SA: 0,
        KW: 0,
        BH: 0,
        QA: 0,
        OM: 0,
        ME: 0
    }

    return (
        <>
            <DayPicker
                {...otherProps}
                numberOfMonths={NO_OF_MONTHS}
                interactionDisabled={true}
                renderDay={renderDay}
                className={dayPickerClassName}
                weekdaysShort={weekSort}
                firstDayOfWeek={FIRST_DAY_OF_WEEK['IN']}
                disabledDays={disabledDays}
                fromMonth={today}
                toMonth={toMonth}
                modifiers={modifiers}
                locale="it"
                months={MONTHS}
                month={range.from || undefined}
                selectedDays={roundTrip ? [from, { from, to: enteredTo }] : selectedDate}
                navbarElement={Navbar}
                onDayClick={(day: Date) => handleDayClick(day)}
                onDayMouseEnter={(day: Date) => handleDayMouseEnter(day)}
            />
        </>
    )
}

export default Calendar
