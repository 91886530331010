import { Container, Modal } from '@cleartrip/ct-design-components'
import React, { FC, useEffect } from 'react'
import CfwErrorModalButton from './CfwErrorModalButton'
import { getCookie } from '@/utils/general/cookie'
import { ravenSDKTrigger } from '@/analytics/raven/ravenSDKHelper'

type ErrorModalType = {
    cfwErrorModal: String
    cfwErrorModalButtonText: String
    isCfwSelected: boolean
    setActiveFareHandler: (id: string) => void
    setIsCfwSelected: React.Dispatch<React.SetStateAction<boolean>>
    isIntlArray: boolean[]
    setCfwErrorModal: React.Dispatch<React.SetStateAction<string>>
    setCfwErrorModalButtonText: React.Dispatch<React.SetStateAction<string>>
    setActiveFare: React.Dispatch<React.SetStateAction<string>>
}
const CfwErrorModal: FC<ErrorModalType> = ({
    cfwErrorModal,
    cfwErrorModalButtonText,
    isCfwSelected,
    setActiveFareHandler,
    setCfwErrorModal,
    setIsCfwSelected,
    isIntlArray,
    setCfwErrorModalButtonText,
    setActiveFare
}) => {
    useEffect(() => {
        if (cfwErrorModal) {
            const ravenPayload = {
                popup_name: 'in_eligible_selection_to_continue_cfw',
                action_type: 'popup',
                page_name: 'flights_home',
                a_utm_source:
                    location?.search?.split?.('utm_source=').length > 1
                        ? location?.search?.split?.('utm_source=')?.pop()?.split('&')?.[0]
                        : getCookie('utm_source') || 'organic'
            }
            ravenSDKTrigger('a_popup_load', ravenPayload)
        }
    }, [cfwErrorModal])
    return (
        <Modal
            className="br-4 p-12 "
            width="392px !important"
            open={cfwErrorModal !== ''}
            size="NONE"
            hideCrossIcon={true}
            onClose={() => {
                setCfwErrorModal('')
                if(isIntlArray[0] || isIntlArray[1]) {
                    setIsCfwSelected(false)
                }
            }}
        >
            <Container className="flex flex-column flex-between px-6 py-6 p-relative" rowGap="32px">
                <Container>
                    <Container className="fs-24 c-black-80 fw-600 mb-4">Please Note</Container>
                    <Container className="mt-4 fs-16 c-black-80 fw-400" style={{ width: '344px', lineHeight: '22px' }}>
                        {cfwErrorModal}
                    </Container>
                    <CfwErrorModalButton
                        cfwErrorModal={cfwErrorModal}
                        cfwErrorModalButtonText={cfwErrorModalButtonText}
                        isCfwSelected={isCfwSelected}
                        setActiveFareHandler={setActiveFareHandler}
                        setIsCfwSelected={setIsCfwSelected}
                        isIntlArray={isIntlArray}
                        setCfwErrorModal={setCfwErrorModal}
                        setCfwErrorModalButtonText={setCfwErrorModalButtonText}
                        setActiveFare={setActiveFare}
                    />
                </Container>
            </Container>
        </Modal>
    )
}

export default CfwErrorModal
