import { Checkbox, Container, Typography } from '@cleartrip/ct-design-components'
import React, { FC, useEffect } from 'react'
import CFWErrorModalDescrirption from '../CfwErrorModal/CfwErrorModalDescription'
import { isUserSignedIn } from '@/utils/general/user'
import BorderAnimation from './BorderAnimation'
import { getCookie } from '@/utils/general/cookie'
import { ravenSDKTrigger } from '@/analytics/raven/ravenSDKHelper'
import CfwCheckboxTooltip from './CfwCheckboxTooltip'

type HomeFormType = {
    setCfwErrorModalButtonText: (value: string) => void
    setCfwErrorModal: (value: string) => void
    activeFare: String
    isIntlArray: boolean[]
    setIsCfwSelected: (value: boolean) => void
    isCfwSelected: boolean
    setCfwModal: (value: boolean) => void
}
const CFWHomePageCallout: FC<HomeFormType> = ({
    setCfwErrorModalButtonText,
    setCfwErrorModal,
    activeFare,
    isIntlArray,
    setIsCfwSelected,
    isCfwSelected,
    setCfwModal
}) => {
    const onCheckboxClick = () => {
        if (isIntlArray[0] || isIntlArray[1]) {
            setCfwErrorModal('Cleartrip for Work can only be accessed for domestic flights')
            setCfwErrorModalButtonText('Okay, got it')
            return
        }
        setCfwErrorModalButtonText('Switch to Cleartrip for Work')

        if (activeFare === 'senior_citizen') {
            setCfwErrorModal('Cleartrip for Work doesn’t include the benefits of Senior citizen fare')
            return
        } else if (activeFare === 'student') {
            setCfwErrorModal('Cleartrip for Work doesn’t include the benefits of Student fare')
            return
        } else if (activeFare === 'army') {
            setCfwErrorModal('Cleartrip for Work doesn’t include the benefits of Armed forces fare')
            return
        }

        const ravenPayloadForCfwClick = {
            action_name: isCfwSelected ? 'cfw_widget_checkbox_uncheck_click' : 'cfw_widget_checkbox_click',
            action_type: 'click',
            page_name: 'flights_home',
            a_utm_source:
                location?.search?.split?.('utm_source=').length > 1
                    ? location?.search?.split?.('utm_source=')?.pop()?.split('&')?.[0]
                    : getCookie('utm_source') || 'organic'
        }

        ravenSDKTrigger('a_ui_action', ravenPayloadForCfwClick)

        if (!isCfwSelected) {
            let cfwHomepageBannerClicksCount = localStorage.getItem('cfwHomepageBannerClicksCount') || 0
            localStorage.setItem('cfwHomepageBannerClicksCount', String(Number(cfwHomepageBannerClicksCount) + 1))
            if (Number(cfwHomepageBannerClicksCount) < 5) {
                setCfwModal(true)
            }
        }
        setIsCfwSelected(!isCfwSelected)
    }
    useEffect(() => {
        if (isUserSignedIn()) {
            setIsCfwSelected(true)
        }
    }, [isUserSignedIn])

    return (
        <Container className="p-relative" style={{width: '290px'}}>
            <CfwCheckboxTooltip />
            <BorderAnimation className="mb-16 br-12">
                <Container
                    className="px-3 py-2 br-8 flex flex-middle"
                    background={
                        'linear-gradient(88deg, rgba(255, 255, 255, 0.00) 3%, rgba(188, 213, 255, 0.60) 98.19%)'
                    }
                    onClick={onCheckboxClick}
                >
                    <Container>
                        <Checkbox checked={isCfwSelected} />
                    </Container>
                    <Container className="flex flex-column">
                        <Container className="flex flex-row" style={{ gap: '8px' }}>
                            <Container>
                                <Typography variant="B2">Unlock 10% extra savings</Typography>
                            </Container>
                            <Container
                                className="fs-10 flex flex-middle fw-600 pt-1 pb-1 pl-1 pr-1"
                                backgroundColor="#FF4F17"
                                style={{ borderRadius: '4px', height: '16px', color: '#FFFFFF', marginTop: '2px' }}
                            >
                                NEW
                            </Container>
                        </Container>
                        {/* <CFWErrorModalDescrirption /> */}
                        <Typography variant="B3" color="subheading" className="ws-nowrap">
                            Cleartrip for Work
                        </Typography>
                    </Container>
                </Container>
            </BorderAnimation>
        </Container>
    )
}

export default CFWHomePageCallout
