import React, { useState, useEffect } from 'react'
import { Container, Spacer, Typography, Chip } from '@cleartrip/ct-design-components'
import { Dropdown, DropdownItem, DropdownMenu, DropdownSelect } from '@/components/oldComponents/Dropdown'
import { TickIcon, OnewayIcon, RoundtripIcon, UpChevron } from '@/assets/icons'
import { propsTraveller } from '@/mocks/travellermock'
import { Raven } from '@/analytics/raven'
import { RAVEN_EVENT_NAME, RAVEN_PAGE_NAME } from '@/analytics/raven/ravenConstants'

interface JourneyTypeProps {
    journeyType: string
    tripTypeHandler: (arg0: string) => void
}

const JourneyType: React.FC<JourneyTypeProps> = ({ journeyType, tripTypeHandler }) => {
    const [tripType, setTripType] = useState(journeyType === 'R' ? 'Round trip' : 'One way')
    const [openMenu, setOpenMenu] = useState(false)

    useEffect(() => {
        if (journeyType !== tripType) {
            setTripType(journeyType === 'R' ? 'Round trip' : 'One way')
        }
    }, [journeyType])
    const tripTypes = [
        {
            id: 1,
            label: 'One way',
            value: 'oneway',
            selected: true
        },
        {
            id: 2,
            label: 'Round trip',
            value: 'rt',
            selected: false
        }
    ]
    function tripSelect(value: string) {
        tripTypeHandler(value === 'One way' ? 'O' : 'R')
        Raven.push({
            eventName: `a_hp_${value === 'One way' ? 'ow' : 'rt'}_journey_clicked`,
            eventData: {
                action_name: `a_hp_${value === 'One way' ? 'ow' : 'rt'}_journey_clicked`,
                page_name: RAVEN_PAGE_NAME.FLIGHTS_HOME
            }
        })
        setTripType(value)
        setOpenMenu(!openMenu)
    }
    function toggleFn() {
        Raven.push({
            eventName: RAVEN_EVENT_NAME.HOME_JOURNEY_CLICKED,
            eventData: {
                action_name: RAVEN_EVENT_NAME.HOME_JOURNEY_CLICKED,
                page_name: RAVEN_PAGE_NAME.FLIGHTS_HOME
            }
        })
        setOpenMenu(!openMenu)
    }
    function handleClick() {
        setOpenMenu(!openMenu)
    }
    const tripTypeDropdown = tripTypes.map(item => {
        return (
            <DropdownItem
                key={item.id}
                onClick={() => tripSelect(item.label)}
                className="c-pointer px-4 py-2 dropdown__item"
            >
                <Container className="flex flex-middle flex-cg-3">
                    {item.label === tripType ? <TickIcon /> : <Spacer className="ml-6" />}
                    <Typography variant="B1" className="c-pointer">
                        {item.label}
                    </Typography>
                </Container>
            </DropdownItem>
        )
    })
    const getLabel = (open: boolean, tripType: string) => {
        return tripType === 'One way' ? (
            <>
                <div className="flex flex-middle">
                    <OnewayIcon />
                    <span className="fw-500 fs-4 ml-2" style={{ lineHeight: '24px' }}>
                        One way
                    </span>
                    <span
                        className="ml-1 flex"
                        style={{ rotate: open ? '0deg' : '180deg', transition: 'ease .5s rotate' }}
                    >
                        <UpChevron />
                    </span>
                </div>
            </>
        ) : (
            <>
                <div className="flex flex-middle">
                    <RoundtripIcon />
                    <span className="fw-500 fs-4 ml-2" style={{ lineHeight: '24px' }}>
                        Round trip
                    </span>
                    <span
                        className="ml-1 flex"
                        style={{ rotate: open ? '0deg' : '180deg', transition: 'ease .5s rotate' }}
                    >
                        <UpChevron />
                    </span>
                </div>
            </>
        )
    }
    return (
        <Container style={{ width: '180px' }}>
            <Dropdown
                onOutsideClick={() => {
                    setOpenMenu(false)
                }}
            >
                <DropdownSelect
                    size={'sm'}
                    name="travellers"
                    getLabel={open => getLabel(open, tripType)}
                    className="fs-2"
                    tabIndex={4}
                    onClick={handleClick}
                    toggleDropdown={toggleFn}
                    border={false}
                    open={openMenu}
                />
                <DropdownMenu className="py-2" open={openMenu} distance={-12}>
                    {tripTypeDropdown}
                </DropdownMenu>
            </Dropdown>
        </Container>
    )
}

export default JourneyType
