export const defaultAbValues = {
    gh35: 'a',
    gh30: 'a',
    itinerary_coupon: 'a',
    e_home: 'z',
    singlePax: 'a',
    ctUpgrade: 'c',
    vasExp7: 'b',
    vasExp6: 'b',
    mealImage: 'b',
    master_tuple: 'a',
    fareFamily: 'c',
    ff: 'a',
    gh36: 'b',
    gh26: 'a',
    highRps: 'c',
    otpless: 'd',
    tk_home: 'b',
    tk_itin: 'b',
    mfc: 'a',
    splrt: 'a',
    gh60: 'b',
    intlTravelIns: 'c',
    ptc_fare: 'b',
    gh62: 'a',
    inlineLogin_nudge: 'a',
    newHighRps: 'b',
    New_Coupon_Experience: 'b',
    ff_Intl: 'c'
}
