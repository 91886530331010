import { Container } from '@cleartrip/ct-design-components'
import { useEffect, useState } from 'react'
import './cfwBanner.scss'

type BorderAnimationProps = {
    className?: string
    children?: React.ReactNode
}
const BorderAnimation = ({ className, children }: BorderAnimationProps) => {
    const [animationCompleted, setAnimationCompleted] = useState(false)
    const defaultBorderClass = 'ba'
    let animationBorderClass = 'border-animation'

    if (animationCompleted) {
        animationBorderClass = defaultBorderClass
    }
    useEffect(() => {
        setTimeout(() => {
            setAnimationCompleted(true)
        }, 4000)
    }, [])
    return (
        <Container className={`o-hidden ${className} ${animationBorderClass}`}>
            {!animationCompleted
                ? [...Array(4)].map((_, i) => <Container key={i} className={`border-animation-${i + 1}`}></Container>)
                : ''}
            <Container position="relative" zIndex={2} borderRadius={12} background="#fff">
                {children}
            </Container>
        </Container>
    )
}

export default BorderAnimation
