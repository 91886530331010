import React from 'react'

import {
    RoundedMinus as MinusIconEnabled,
    RoundedPlus as PlusIconEnabled,
    RoundedMinusDisabled as MinusIconDisabled,
    RoundedPlusDisabled as PlusIconDisabled
} from '@/assets/icons'
import { getTestIdProps } from '@/utils/general/components'
import { HOME_PAGE_COMPONENTS_NAME } from '@/constants/components'
import { TEST_IDS } from '@/constants/testId'

interface IOwnProps {
    value: number
    minValue: number
    maxValue: number
    handleSize: number
    onDecrement: () => void
    onIncrement: () => void
    pageName?: string
    testId?: string
}

const Counter: React.FC<IOwnProps> = ({
    value,
    minValue,
    maxValue,
    handleSize,
    onDecrement,
    onIncrement,
    pageName,
    testId
}) => {
    const MinusIcon = value === minValue ? MinusIconDisabled : MinusIconEnabled
    const PlusIcon = value === maxValue || value === handleSize ? PlusIconDisabled : PlusIconEnabled

    const decrementCounter = () => {
        onDecrement()
    }

    const incrementCounter = () => {
        onIncrement()
    }

    return (
        <div className="flex flex-1 flex-middle flex-end">
            <button
                type="button"
                className={`bn ${value === minValue ? '' : 'c-pointer'}`}
                style={{ background: 'none' }}
                onClick={decrementCounter}
                disabled={value === minValue}
                {...getTestIdProps({
                    pageName: pageName,
                    componentName: HOME_PAGE_COMPONENTS_NAME.PAX_COUNTER,
                    testId: TEST_IDS.DECREMENT,
                    prefix: testId
                })}
            >
                <MinusIcon />
            </button>
            <p
                className="fs-16 mx-4 flex flex-middle flex-center"
                style={{ lineHeight: '22px', width: 24 }}
                {...getTestIdProps({
                    pageName,
                    componentName: HOME_PAGE_COMPONENTS_NAME.PAX_PERSON_COUNT,
                    testId
                })}
            >
                {value}
            </p>
            <button
                type="button"
                className={`bn ${value === maxValue || value === handleSize ? '' : 'c-pointer'}`}
                style={{ background: 'none' }}
                onClick={incrementCounter}
                disabled={value === maxValue || value === handleSize}
                {...getTestIdProps({
                    pageName: pageName,
                    componentName: HOME_PAGE_COMPONENTS_NAME.PAX_COUNTER,
                    testId: TEST_IDS.INCREMENT,
                    prefix: testId
                })}
            >
                <PlusIcon />
            </button>
        </div>
    )
}

export default Counter
