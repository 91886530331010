import { TooltipV2 } from '@cleartrip/ct-design-components'
import React, { JSXElementConstructor, ReactElement, useState } from 'react'
import { returnFareName } from 'src/constants/components/fareType'

function TooltipMessage(props: {
    children: ReactElement<any, string | JSXElementConstructor<any>>
    title?: string
    sft: string
    tooltipWidth?: string
    containerWidth?: string
    shouldDisable: boolean
}) {
    const { shouldDisable = false, title = '', sft, tooltipWidth = '200px', containerWidth = '340px' } = props
    const [showToolTip, setShowToolTip] = useState(false)
    return (
        <TooltipV2
            styleConfig={{
                labelContainer: {
                    css: {
                        width: containerWidth
                    }
                },
                tooltipContainer: {
                    css: {
                        color: 'white',
                        width: tooltipWidth,
                        right: '0px',
                        alignItems: 'flex-end'
                    }
                },
                tooltipArrowContainer: {
                    css: {
                        right: '8px',
                        left: 'unset'
                    }
                }
            }}
            position="auto"
            // open={shouldDisable && showToolTip}
            labelContainer={
                <div onMouseLeave={() => setShowToolTip(false)} onMouseOver={() => setShowToolTip(true)}>
                    {props.children}
                </div>
            }
        >
            {shouldDisable && showToolTip && (
                <p className="fs-12 lh-16 p-2">
                    You cannot add {title.toLowerCase()} to {returnFareName(sft).toLocaleLowerCase()}
                </p>
            )}
        </TooltipV2>
    )
}

export default TooltipMessage
