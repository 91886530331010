import React, { ChangeEvent, SyntheticEvent, useRef, useState } from 'react'
import classNames from 'classnames'

// import './style.scss'

// import  {Landing as ReactComponent } from '@/assets/icons';
import { Landing, Takeoff, SwapIcon } from '@/assets/icons'
import { OutsideClickHandler, Spacer } from '@cleartrip/ct-design-components'
import { isServer } from '@/utils'
import { ISuggestion } from '@/types/home'
import DropdownList from './dropdownList'

interface AirportFieldError {
    arrival: boolean
    departure: boolean
}
interface cityType {
    source: string
    destination: string
    sourceCountry: string
    destinationCountry: string
    sCode: string
    dCode: string
    isIntl: string
    departureError: boolean
    arrivalError: boolean
}
interface toastType {
    errorMsg?: string
    closeBtn?: boolean
}
interface AirportFieldProps {
    onArrivalChange: (event: ChangeEvent<HTMLInputElement>) => void
    onDepartureChange: (event: ChangeEvent<HTMLInputElement>) => void
    error: AirportFieldError
    onSelectArrival: (...args: any[]) => void
    onSelectDeparture: (...args: any[]) => void
    arrivalList: ISuggestion[]
    departure: string
    arrival: string
    departureList: ISuggestion[]
    loading: boolean
    arrivalPlaceholder: string
    departurePlaceholder: string
    city: cityType
    setCity: (...args: any[]) => void
    setDeparture: (...args: any[]) => void
    setArrival: (...args: any[]) => void
    toast: toastType
    isCfwSelected: boolean
}

const AirportField = (props: AirportFieldProps) => {
    const {
        onArrivalChange = () => {},
        onDepartureChange = () => {},
        error = { arrival: false, departure: false },
        onSelectArrival = () => {},
        onSelectDeparture = () => {},
        arrivalList,
        departure,
        arrival,
        departureList,
        loading,
        arrivalPlaceholder = 'abc',
        departurePlaceholder = 'abc',
        city,
        setCity = () => {},
        setDeparture = () => {},
        setArrival = () => {},
        toast = {}
    } = props
    const departureRef = useRef<HTMLInputElement>(null)
    const arrivalRef = useRef<HTMLInputElement>(null)
    const bothFieldsEmpty = error.arrival && error.departure
    const [open, setOpen] = useState({
        from: false,
        to: false
    })
    const [swap, setSwap] = useState(false)
    function swapChange(value: boolean) {
        if (departure && arrival) {
            setSwap(value)
            setCity({
                ...city,
                source: city.destination,
                destination: city.source,
                sCode: city.dCode,
                dCode: city.sCode
            })
            setDeparture(arrival)
            setArrival(departure)
            setTimeout(() => {
                setSwap(!value)
            }, 500)
        }
    }
    function onFieldClick(e: SyntheticEvent) {
        const target = e?.target as HTMLInputElement
        target?.select()
    }
    return (
        <OutsideClickHandler onOutsideClick={() => setOpen({ from: false, to: false })}>
            <div
                className={`dropdown-container h-13 bc-line-500 flex ba-solid mt-2 ${
                    toast.errorMsg === 'Enter departure and arrival airports / cities' ? 'error' : ''
                } ${toast.errorMsg === 'Departure and arrival airports / cities cannot be same.' ? 'error' : ''}`}
            >
                <div
                    className={`field-1 h-13 flex flex-middle p-relative pr-4 w-100p ${
                        toast.errorMsg === 'Enter departure airport / city' ? 'error-from' : ''
                    }`}
                    onClick={() => !open.from && departureRef?.current?.focus()}
                >
                    <div className="mx-4">
                        <Takeoff
                            stroke={
                                !isServer() && document.activeElement === departureRef.current ? '#3366CC' : '#808080'
                            }
                        />
                    </div>
                    <input
                        ref={departureRef}
                        className={`w-100p fs-16 fw-500 ${departure ? '' : 'c-neutral-900'}`}
                        style={{ border: 'none' }}
                        placeholder={departurePlaceholder}
                        onFocus={e => setOpen({ to: false, from: true })}
                        onChange={onDepartureChange}
                        onClick={e => onFieldClick(e)}
                        value={departure}
                    />
                    {open.from && (
                        <DropdownList
                            airportList={departureList}
                            onClick={onSelectDeparture}
                            onClose={() => setOpen({ ...open, from: false })}
                            loading={loading}
                        />
                    )}
                </div>
                <div className="p-relative">
                    <div
                        className={`bc-line-500 d-block flex-1 ${
                            toast.errorMsg === 'Enter departure and arrival airports / cities' ? 'error' : ''
                        } ${
                            toast.errorMsg === 'Departure and arrival airports / cities cannot be same.' ? 'error' : ''
                        }`}
                        style={{ height: '51px', borderLeftStyle: 'solid', borderWidth: '1px' }}
                    ></div>
                    <div className="mx-4 p-absolute z-10" style={{ top: '8px', left: '-32px' }}>
                        <SwapIcon
                            className={classNames('c-pointer', swap ? 'rotate180' : null)}
                            onClick={() => {
                                swapChange(!swap)
                            }}
                        />
                    </div>
                </div>
                <div
                    className={`field-2 flex h-13 flex-middle p-relative pr-4 w-100p ${
                        toast.errorMsg === 'Enter arrival airport / city' ? 'error-to' : ''
                    }`}
                    onClick={() => !open.to && arrivalRef?.current?.focus()}
                >
                    <div className="mr-4 pl-8 icon">
                        <Landing
                            stroke={
                                !isServer() && document.activeElement === arrivalRef.current ? '#3366CC' : '#808080'
                            }
                        />
                    </div>
                    <input
                        ref={arrivalRef}
                        className={`w-100p fs-16 fw-500 ${arrival ? '' : 'c-neutral-900'}`}
                        style={{ border: 'none' }}
                        placeholder={arrivalPlaceholder}
                        onFocus={() => setOpen({ from: false, to: true })}
                        onChange={onArrivalChange}
                        onClick={e => onFieldClick(e)}
                        value={arrival}
                    />
                    {open.to && (
                        <DropdownList
                            airportList={arrivalList}
                            onClick={onSelectArrival}
                            onClose={() => setOpen({ ...open, to: false })}
                            loading={loading}
                        />
                    )}
                </div>
            </div>
            {toast.errorMsg && toast.errorMsg !== 'Enter arrival airport / city' && (
                <>
                    <Spacer className="mt-1" />
                    <span className="fw-500 fs-2 ml-4" style={{ color: '#CC1414' }}>
                        {toast.errorMsg}
                    </span>
                </>
            )}
            {toast.errorMsg && toast.errorMsg === 'Enter arrival airport / city' && (
                <>
                    <Spacer className="mt-1" />
                    <span className="fw-500 fs-2" style={{ color: '#CC1414', marginLeft: '334px' }}>
                        {toast.errorMsg}
                    </span>
                </>
            )}
        </OutsideClickHandler>
    )
}

export default AirportField
