import React, { useState, useEffect } from 'react'
import { OutsideClickHandler, Spacer } from '@cleartrip/ct-design-components'
import { DropdownMenu, DropdownSelect } from '@/components/oldComponents/Dropdown'
import { dateToEpoch, getFormattedDate, getInitialMonthRange, getNumberOfDaysBetweenRange } from '@/utils/general/date'
import { isEmpty } from '@/utils/general/browserHelper'
import Calendar from './Calendar/Calendar'
import '@/styles/oldStyles/datePickerStyles.scss'
import baseFetch from '@/network/baseFetch'
import { HOME_API_ENDPOINTS, REQUEST_METHODS } from '@/constants/network'
import { Raven } from '@/analytics/raven'
import { RAVEN_EVENT_NAME, RAVEN_PAGE_NAME } from '@/analytics/raven/ravenConstants'
import Clevertap from '@/analytics/clevertap/index'
import { ACTION_NAMES } from '@/constants/analytics'
interface DateRange {
    from: Date | null
    to: Date | null
    enteredTo: Date | null
}
interface DayData {
    amount: number
    date: string
    farePresent?: boolean
    fareCategory: string
}

interface SearchParams {
    return_date?: string
    from?: string
    to?: string
    destination?: string
    origin?: string
    adults?: string
    childs?: string
    infants?: string
    class?: string
    sft?: string
}

interface DateRangePickerProps {
    roundTrip: boolean
    departDate: string
    returnDate: string
    dateClick: any
    searchParams: SearchParams
    index?: number
    onwardJourny?: string
    returnJourny?: string
    fromCode?: string
    toCode?: string
    journeyType: string
    showDateFare: boolean
}

const DateRangePicker: React.FC<DateRangePickerProps> = props => {
    const {
        roundTrip,
        departDate,
        returnDate,
        dateClick,
        searchParams,
        index = 1,
        onwardJourny = '',
        returnJourny = '',
        fromCode = '',
        toCode = '',
        journeyType,
        showDateFare
    } = props

    const [activeOnward, setActiveOnward] = useState<boolean>(false)
    const [activeReturn, setActiveReturn] = useState<boolean>(false)
    const [openCalendar, setOpenCalendar] = useState<boolean>(false)
    const [isRoundTrip, setIsRoundTrip] = useState<boolean>(roundTrip)
    const [fareCalendar, setFareCalendar] = useState<Record<string, any>>({})
    const [flexiData, setFlexiData] = useState<Record<string, any>>({})

    let formDate = departDate ? new Date(getFormattedDate(departDate, 'MM/DD/YYYY', 'DD/MM/YYYY')) : new Date()
    let toDate = returnDate ? new Date(getFormattedDate(returnDate, 'MM/DD/YYYY', 'DD/MM/YYYY')) : formDate

    const [range, setRange] = useState<DateRange>({ from: formDate, to: toDate, enteredTo: toDate })

    useEffect(() => {
        setRange({
            ...range,
            from: formDate
        })
    }, [departDate])

    useEffect(() => {
        setIsRoundTrip(roundTrip)
        if (!roundTrip) {
            setRange({
                ...range,
                to: null,
                enteredTo: null,
                from: formDate
            })
        } else {
            if (searchParams.return_date) {
                setRange({
                    ...range,
                    to: toDate,
                    enteredTo: toDate,
                    from: formDate
                })
            }
        }
    }, [activeReturn, roundTrip])

    function handleOnwardClick() {
        getDateRangePrice(getInitialMonthRange(departDate))
        setActiveOnward(!activeOnward)
        setActiveReturn(false)
        if (openCalendar && !activeReturn) {
            setOpenCalendar(!openCalendar)
        } else {
            Raven.push({
                eventName: RAVEN_EVENT_NAME.HOME_OW_CALENDAR_OPENED,
                eventData: {
                    action_name: RAVEN_EVENT_NAME.HOME_OW_CALENDAR_OPENED,
                    page_name: RAVEN_PAGE_NAME.FLIGHTS_HOME,
                    fare_available_for_days: 60
                }
            })
            setOpenCalendar(true)
        }
    }

    function handleReturnClick() {
        setActiveOnward(false)
        setActiveReturn(!activeReturn)
        setFareCalendar({})
        if (openCalendar && !activeOnward) {
            setOpenCalendar(!openCalendar)
        } else {
            getDateRangePrice(getInitialMonthRange(departDate), true)
            Clevertap.event('Air_UI_Action', {
                action_type: 'BUTTON',
                action_name: ACTION_NAMES.hpReturnOnClicked
            })
            Raven.push({
                eventName: RAVEN_EVENT_NAME.HOME_RT_CALENDAR_OPENED,
                eventData: {
                    action_name: RAVEN_EVENT_NAME.HOME_RT_CALENDAR_OPENED,
                    page_name: RAVEN_PAGE_NAME.FLIGHTS_HOME,
                    fare_available_for_days: 60
                }
            })
            setOpenCalendar(true)
        }
    }

    function directionClick(departRange: any) {
        getDateRangePrice(departRange)
    }

    function formatDate(date: Date | null) {
        if (date) {
            return getFormattedDate(date, 'ddd, MMM D')
        }
    }

    function setDates(dates: DateRange) {
        setRange({ ...range, ...dates })
        dateClick(dates, range)
        if (isRoundTrip && dates.to) {
            setOpenCalendar(false)
            setActiveOnward(false)
            setActiveReturn(false)
        }
        if (isRoundTrip && dates.from && journeyType === 'R') {
            setActiveOnward(false)
            handleReturnClick()
        } else {
            setOpenCalendar(false)
            setActiveOnward(false)
            setActiveReturn(false)
        }
    }

    function outsideCalendar() {
        setOpenCalendar(false)
        setActiveReturn(false)
        setActiveOnward(false)
    }

    const getDateRangePrice = async (departRange: any, roundtrip = false) => {
        if(!showDateFare)
            return
        const [startDateStr] = departRange.split('_')
        const fareCalendarPayload = {
            fareCalendarType: 'CHEAPEST',
            departDetails: !roundtrip
                ? {
                      from: !isEmpty(fromCode) ? fromCode : searchParams?.from,
                      to: !isEmpty(toCode) ? toCode : searchParams?.to,
                      startDate: dateToEpoch(startDateStr)
                  }
                : {
                      from: !isEmpty(toCode) ? toCode : searchParams?.to,
                      to: !isEmpty(fromCode) ? fromCode : searchParams?.from,
                      startDate: dateToEpoch(startDateStr)
                  },

            paxDetails: {
                adult: searchParams?.adults || 1,
                child: searchParams?.childs || 0,
                infant: searchParams?.infants || 0
            },
            fareDetails: {
                domain: 'IN',
                cabinType: searchParams.class?.toUpperCase() || 'ECONOMY',
                fareType: 'RETAIL',
                fareGroup: searchParams.sft?.toUpperCase() || 'REGULAR',
                partnerId: '10000000'
            },
            numberOfDays: getNumberOfDaysBetweenRange(departRange),
            journeyType: !roundtrip ? 'ONE_WAY' : 'ROUND_TRIP',
            departDate: !roundtrip ? '' : dateToEpoch(departDate)
        }

        let response = await baseFetch<{ dayData: DayData[] }>({
            body: JSON.stringify(fareCalendarPayload),
            url: HOME_API_ENDPOINTS.DATE_TABS,
            method: REQUEST_METHODS.POST
        })

        const newResponse = response?.dayData ?? []
        const hasDayData = newResponse.length > 0

        const temp = hasDayData
            ? newResponse.map(day => ({
                  pr: day.farePresent ? Number(day.amount) : '-',
                  date: day.date,
                  isCheapest: day.fareCategory === 'CHEAPEST'
              }))
            : []

        setFlexiData(prev => ({
            onward: temp,
            ...(roundtrip && { return: temp })
        }))
    }

    useEffect(() => {
        if (!isEmpty(flexiData)) {
            let flexiSearchData = { ...flexiData }
            if (!isEmpty(flexiSearchData)) {
                setFareCalendar(reformFareCalResponse(flexiSearchData))
            }
        }
    }, [flexiData])

    function reformFareCalResponse(response: any) {
        const onward: Record<string, any> = {}
        const cheapFare: Record<string, any> = {}
        if (response.onward) {
            response.onward.forEach((elem: any) => {
                onward[elem.date] = elem
                const fullMonth = getFormattedDate(elem.date, 'DD-MMMM-YYYY', 'DD/MM/YYYY')
                const month = fullMonth.split('-')[1]
                const curPrice = elem.pr || '-'
                if (cheapFare[month]) {
                    if (parseInt(cheapFare[month], 10) > parseInt(curPrice, 10) && curPrice !== '-') {
                        cheapFare[month] = curPrice
                    }
                } else if (curPrice !== '-') {
                    cheapFare[month] = curPrice
                }
            })
        }
        return { ...response, onward, cheapFare }
    }

    return (
        <OutsideClickHandler onOutsideClick={() => outsideCalendar()}>
            <div className="flex flex-middle p-relative homeCalender">
                <DropdownSelect
                    className="c-neutral-900"
                    width={191}
                    size={'lg'}
                    name="from"
                    hasError={false}
                    active={activeOnward}
                    label={formatDate(range.from)}
                    onClick={handleOnwardClick}
                    placeHolder={'Onward'}
                />
                <DropdownSelect
                    className="c-neutral-900"
                    width={185}
                    size={'lg'}
                    active={activeReturn}
                    label={journeyType === 'O' ? '' : formatDate(range.to)}
                    name="to"
                    placeHolder={'Return'}
                    onClick={handleReturnClick}
                />
                <DropdownMenu open={openCalendar} distance={0} className="t-52 br-12" width={764}>
                    <Spacer className="pt-3" />
                    <div
                        className="p-absolute"
                        style={{ left: '50%', height: '90%', top: 25, borderLeft: '1px dotted rgb(187, 187, 187)' }}
                    ></div>
                    <Calendar
                        className="w-100p"
                        title="New Delhi → Bangalore"
                        selectedDates={range}
                        roundTrip={isRoundTrip}
                        onClickCalendar={setDates}
                        fareCalendar={fareCalendar}
                        directionClick={directionClick}
                        activeOnward={activeOnward}
                        activeReturn={activeReturn}
                        index={index}
                        showDateFare={showDateFare}
                    />
                </DropdownMenu>
            </div>
        </OutsideClickHandler>
    )
}

export default DateRangePicker
